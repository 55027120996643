import React from "react";
import { formatNumber } from "@/res/helpers/ObjectHelpers";
import Associates from "@/res/components/ServicePages/Associates";
import Payroll from "@/res/components/ServicePages/Payroll";
import AnnualReportAccounting from "@/res/components/ServicePages/CountrySpecific/Norway/AnnualReportAccounting";
import FormerAccountantAuditor from "@/res/components/ServicePages/FormerAccountantAuditor";
import Basics from "@/res/components/ServicePages/ServiceSpecific/acno/Basics";
import Sales from "@/res/components/ServicePages/ServiceSpecific/acno/Sales";

const priceData = (props) => [
    {
        type: "PAYG",
        name: "Minuttpris",
        hourly: formatNumber(749),
        details: [{
            title: "749,- per time",
            description:
                <>
                    <p>
                        Betal kun for minuttene som brukes, ikke per påbegynt kvarter.
                    </p>
                </>,
        }],
    },
    {
        type: "FIXED",
        name: "Fastpris",
        details: [{
            title: "Etter avtale",
            description:
                <>
                    <p>
                        Få et uforpliktende tilbud som du kan vurdere før arbeidet begynner.
                    </p>
                </>,
        }],
    },
];

const categoryData = [
    {
        internal_id: 0,
        sales_only: true,
        name: "Spesifisert Avtale",
        color: "#77a6c3",
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Selecting all relevant services/options - Sales.";
                    return <Sales {...props}/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Declaring all relevant associates/signers - Sales.";
                    return <Associates {...props}/>
                },
            ],
        ],
    },
    {
        internal_id: 1,
        name: "Hjelp til hele regnskapet",
        color: "#77a6c3",
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Selecting starting date and accounting software.";
                    return <Basics {...props}/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Configuring their payroll details.";
                    return <Payroll {...props}/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Declaring previous accountants/auditors.";
                    return <FormerAccountantAuditor {...props}/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Declaring all relevant associates/signers.";
                    return <Associates {...props}/>
                },
            ],
        ],
    },
    {
        internal_id: 2,
        name: "Kontroll av regnskapet",
        color: "#f2cf89",
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Selecting starting date and accounting software.";
                    return <Basics {...props}/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Declaring previous accountants/auditors.";
                    return <FormerAccountantAuditor {...props}/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Configuring reporting responsibilities.";
                    return <AnnualReportAccounting {...props}/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Declaring all relevant associates/signers.";
                    return <Associates {...props}/>
                },
            ],
        ],
    },
    {

        internal_id: 3,
        name: "Enkelttimer",
        color: "#77c3b1",
        stages: [
            [
                // BASICS MODIFIED
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Declaring all relevant associates/signers.";
                    return <Associates {...props}/>
                },
            ],
        ],
    },
    {

        internal_id: 4,
        name: "Holdingsselskap",
        color: "#43a1a7",
        stages: [
            [
                // BASICS MODIFIED
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Declaring previous accountants/auditors.";
                    return <FormerAccountantAuditor {...props}/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Declaring all relevant associates/signers.";
                    return <Associates {...props}/>
                },
            ],
        ],
    },
    {

        internal_id: 5,
        name: "Avvikling",
        color: "#e794a8",
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Selecting starting date and accounting software.";
                    return <Basics {...props}/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Declaring previous accountants/auditors.";
                    return <FormerAccountantAuditor {...props}/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Declaring all relevant associates/signers.";
                    return <Associates {...props}/>
                },
            ],
        ],
    },
];

const softwareData = (t) => [
    {
        label: "Velg regnskapsprogram...",
    },
    {
        internal_id: 1,
        label: "Fiken",
    },
    {
        internal_id: 12,
        label: "DNB",
    },
    // {
    //     internal_id: 2,
    //     label: "Visma eAccounting",
    // },
    {
        internal_id: 3,
        label: "Tripletex",
    },
    {
        internal_id: 7,
        label: "PowerOffice Go",
    },
    {
        internal_id: 14,
        label: "Conta",
    },
]

const finalPage = () => {
    return (
        <div>
            <p>
                Alle som selger regnskapstjenester er pålagt av Finanstilsynet å skrive oppdragsavtale med kundene sine.
                Avtalen, som er basert på en mal utarbeidet av Regnskap Norge, er ganske lang da vi må ha med en del
                detaljer på ting som arbeidsfordeling, behandling av data, og så videre.
            </p>
            <p>
                Kort oppsummert skrives det under på:
            </p>
            <ul>
                <li>
                    Arbeidsfordelingen mellom deg og din regnskaps­fører.
                </li>
                <li>
                    At avtalen ikke har noen oppsigelsestid og at du kan avslutte når du vil.
                </li>
                <li>
                    At du har rett til å signere avtalen på vegne av din bedrift.
                </li>
            </ul>
        </div>
    );
}

const Acno = {
    // init: (context) => {
    //     let payload = {}
    //     setPath(payload, "config.check_aml", true);
    //     context?.dispatch({ type: context?.defaultAction, payload: payload});
    // },

    props: () => {
        return {
            checkAml: true,
            prices: priceData,
            services: categoryData,
            softwares: softwareData,
            finalPage: finalPage,
        };
    },
}

export default Acno;
