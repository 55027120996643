import React, { useEffect, useState } from "react";
import examples from "libphonenumber-js/examples.mobile.json";
import parsePhoneNumber, { AsYouType, isValidPhoneNumber, getExampleNumber, getCountryCallingCode } from "libphonenumber-js";

import Norway from "../../assets/icons/flags/norway.svg";
import Australia from "../../assets/icons/flags/australia.svg";
import GreatBritain from "../../assets/icons/flags/great_britain.svg";
import Sweden from "../../assets/icons/flags/sweden.svg";
import Denmark from "../../assets/icons/flags/denmark.svg";
import Germany from "../../assets/icons/flags/germany.svg";
import India from "../../assets/icons/flags/india.svg";
import France from "../../assets/icons/flags/france.svg";
import UnitedStates from "../../assets/icons/flags/united-states.svg";
import Default from "../../assets/icons/flags/default.svg";
import ExpandArrow from "../../assets/icons/chevron_down.svg";

import useForm from "@/res/hooks/Form/useForm";
import { resolvePath } from "../../helpers/ObjectHelpers";
import { useTranslation } from "react-i18next";

// For easy pre-sorting, just use this tool https://onlinestringtools.com/sort-strings
const countries = {
    default: { icon: Default },
    AU: { icon: Australia },
    DE: { icon: Germany },
    DK: { icon: Denmark },
    FR: { icon: France },
    IN: { icon: India },
    NO: { icon: Norway },
    SE: { icon: Sweden },
    GB: { icon: GreatBritain },
    US: { icon: UnitedStates },
}

const usePhoneInput = (props) => {
    const [t] = useTranslation();

    const [open, setOpen] = useState(false);
    const [dirty, setDirty] = useState(false);
    const { values, forceValue } = useForm({ key: props?.key, context: props?.context });
    const rule = props?.rule ?? props?.rules ?? {};

    const key = props?.key ?? "phoneInput";
    const elementId = `usePhoneInput${key}`;
    const context = props?.context;
    const value = context != null ? resolvePath(context?.state, key) : values[key];
    const IS_SERVER = typeof window === "undefined";

    useEffect(() => {
        if (IS_SERVER) return;
        if (rule?.ignoreDefault ?? false) return;
        setInitialDialCode();
        isValid();
    }, []);

    useEffect(() => {
        if (rule?.default?.iso_3166?.length === 2 && (value == null ||
            (!dirty && rule?.default?.forceUpdate))) {
            setDirty(true);
            setInitialDialCode(true);
        }
    }, [props?.rule]);

    useEffect(() => {
        if (rule?.disabled ?? false) {
            setOpen(false);
        }
    }, [rule?.disabled]);

    const setInitialDialCode = (force = false) => {
        let iso_3166 = null;
        try {
            if (rule?.default?.iso_3166?.length === 2) {
                iso_3166 = rule?.default?.iso_3166?.toUpperCase?.();
            } else {
                iso_3166 = window.location.pathname.split("/")[1]?.toUpperCase?.();
            }
        } catch {
            iso_3166 = "NO";
        }

        switch (iso_3166) {
            case "UK":
                iso_3166 = "GB";
                break;
            case "FORMS":
            case "GLOBAL":
                iso_3166 = "NO";
                break;
            default:
                break;
        }

        let exampleCountry = getExampleNumber(iso_3166, examples);
        if (exampleCountry == null) {
            console.error("Country is unknown:", iso_3166)
        } else {
            if ((value?.["e.164"]?.length ?? 0) <= 0 || force) {
                return setValue(exampleCountry?.countryCallingCode, { country: iso_3166 })
            }
        }
    }

    const handleChangeMiddleman = (e) => {
        let target = e.target.value;
        setDirty(true);
        setValue(target);
        isValid(e.target.value);
    }

    const setValue = (val, extra = null) => {
        if (typeof val !== "object") {
            let target = val[0] === "+" ? val : "+" + val;
            let pn = parsePhoneNumber(target);

            let country = null;
            let dial_code = null;
            if (value?.dial_code === pn?.countryCallingCode || val?.match?.(new RegExp(`^[+]${value?.dial_code}`))) {
                country = value?.country;
                dial_code = value?.dial_code;
            }
            if (country === null && pn?.country == null) {
                let countries = pn?.metadata?.country_calling_codes?.[pn?.countryCallingCode ?? dial_code];
                if (countries?.length > 0) {
                    country = countries[0];
                }
            }

            val = {
                "e.164": pn?.number ?? `+${target.replace(/\D/g, "")}`,
                dial_code: pn?.countryCallingCode ?? extra?.dial_code ?? dial_code,
                country: pn?.country ?? extra?.country ?? country,
                number: pn?.nationalNumber,
            }
        }

        if (typeof val === "object") {
            if ((val["e.164"]?.length ?? 0) === 0 || (val["e.164"].replace(new RegExp(`^[+]${val?.dial_code}`), "")?.length ?? 0) === 0) {
                val["e.164"] = `+${val.dial_code}${val.number}`;
            }
        }

        forceValue(key, val);
        return val;
    };

    const isValid = (v = null) => {
        if (IS_SERVER) return true;

        let item = document.getElementById(elementId);
        let _value = v != null ? v : value?.["e.164"];
        let is_valid = isValidPhoneNumber(_value ?? "");
        let msg = "";

        if (!is_valid) {
            if ((rule?.required ?? true) && (_value?.length ?? 0) <= 3) {
                msg = t("_shared.error.phone-number.required");
                item?.setCustomValidity?.(msg.length > 0 ? msg : "The field is not valid.");
            }

            if (_value?.length >= 4) {
                msg = t("_shared.error.phone-number.invalid-format");
                item?.setCustomValidity?.(msg.length > 0 ? msg : "The field is not valid.");
            }

            return false;
        }

        item?.setCustomValidity?.("");
        return true;
    }

    const displayFormat = () => {
        return (new AsYouType().input(value?.["e.164"] ?? ""));
    };

    const selectCountry = (key) => {
        if (rule?.disabled ?? false) return;

        if (key !== value?.country) {
            let dial_code = getCountryCallingCode(key);
            setValue(dial_code, { country: key, dial_code: dial_code })
        }
    }

    const reset = () => {
        setDirty(true);
        return setInitialDialCode(true);
    };

    const render = () => {
        return (
            <div className="phone-container">
                { props?.label &&
                <label htmlFor={ elementId }>
                    { props?.label }
                </label>
                }
                <div className={ `input-container prefix ${props?.class}` }>
                    <div
                        className={ `prefix-addon flag-addon ${props?.class} ${ (rule?.disabled ?? false) ? "disabled" : "" }` }
                        onClick={ () => setOpen(!open) }
                    >
                        <img src={countries?.[value?.country]?.icon ?? countries["default"].icon} alt="" loading="lazy"/>
                        <span>
                            <img src={ExpandArrow} alt=""/>
                        </span>

                        <div className={ "country-list" + (open ? " open" : "") }>
                            { Object.keys(countries ?? {}).map((key, index) => {
                                const item = countries[key];
                                if (key?.length !== 2) return;

                                return (
                                    <div key={index} onClick={() => selectCountry(key)}>
                                        <div>
                                            <div>
                                                <img src={item?.icon} alt=""/>
                                            </div>
                                            <div>
                                                <label>
                                                    { t(`_shared.countries.${key.toLowerCase()}`) } <span>+{ getCountryCallingCode(key) }</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <input
                        id={ elementId }
                        type="tel"
                        name={ key }
                        placeholder={ props?.placeholder ?? "1 (702) 123-4567" }
                        autoComplete="tel"
                        value={ displayFormat() }
                        onChange={ props?.onChange ?? handleChangeMiddleman}
                        onKeyUp={ props?.onKeyUp }
                        onFocus={ props?.onFocus }
                        className={ props?.class + (isValid() ? "" : " invalid") }
                        required={ rule?.required ?? true}
                        disabled={ rule?.disabled ?? false }
                    />
                </div>
            </div>
        );
    }

    return {
        value, render, reset, isValid, setValue,
    };
};

export default usePhoneInput;
