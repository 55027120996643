import React, { useEffect, useState } from "react";
import Head from "next/head";
import Nav from "@/res/components/nav";
import { changeLanguage } from "@/res/helpers/i18n";
import "@/res/helpers/i18n";
import "../styles/globals.scss";
import "@/res/styles/application.scss";
import axios from "axios";
import { useRouter } from "next/router";
import * as gtag from "@/res/helpers/GoogleTag";
import * as gtm from "@/res/helpers/GoogleTagManager";
import * as Sentry from "@sentry/nextjs";
import { GenericWrapper } from "@/res/context/generic";

global.Sentry = Sentry;

function MyApp({ Component, pageProps }) {
    const [logoClassname, setLogoClassname] = useState(null);
    const [isCrmEnabled, setCrmEnabled] = useState(false);

    const router = useRouter();
    const isProduction = process.env.NODE_ENV === "production";

    useEffect(() => {
        if (!isProduction) return;

        const handleRouteChange = (url) => {
            gtag.pageview(url);
            gtm.pageview(url);
        }

        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);

    useEffect(() => {
        let paths = router.asPath.split("/");
        if (paths?.length > 1 && paths[1].length > 0 && (paths[1] !== "forms")) {
            changeLanguage(paths[1]);
        }

        let classname = null;
        if (!!location.href.match(/\/crm\//)) {
            classname = "logo-crm"
            setCrmEnabled(true);
        } else {
            setCrmEnabled(false);
        }

        // TODO, temp fix to an IDFY issue! Try to remove this bit within a month of adding code (27.07.2021)
        if (!!location.href.match(/\/bli-kunde\/signering-suksess\/\?company/)) {
            const isLocalhost = !!location.href.match(/localhost:/);
            location.href = (isLocalhost ? "http://localhost:3098" : "https://www.synega.com") + location.pathname + location.search;
        }

        setLogoClassname(classname);
    });

    return (
        <>
            { isProduction && gtag.render() }
            { isProduction && gtm.render() }

            <Head>
                <title>Signup | Synega</title>
                <meta property="og:title" content="Signup | Synega"/>
                <meta name="twitter:title" content="Signup | Synega"/>

                <meta property="og:site_name" content="Signup | Synega"/>
                <meta property="og:type" content="website"/>

                <meta name="twitter:image" content="/synega-signup.png"/>
                <meta property="og:image" content="/synega-signup.png"/>

                <meta property="og:url" content="https://signup.synega.com"/>
                <meta name="author" content="Synega AS"/>
            </Head>
            {/*<ContactModal/>*/}
            {/*<div className="background-container">*/}
            {/*    <div className="default-background">*/}
            {/*        <div/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <GenericWrapper>
                <Nav className={logoClassname ?? ""} isCrmEnabled={isCrmEnabled}/>
                <Component {...pageProps} />
            </GenericWrapper>
        </>
    );
}

export default MyApp

axios.interceptors.response.use(function (response) {
    if (process?.env?.NODE_ENV === "development" && typeof window !== "undefined") {
        console.log("Development Log", response?.data)
    }

    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    const version_index = response?.config?.url?.indexOf("/api/v");
    if (version_index >= 0) {
        const api_version = parseInt(response?.config?.url?.substr(version_index, 8)?.match(/[0-9]+/g)?.join("") ?? 0);
        if (api_version >= 2) {
            return response?.data;
        }
    }

    return response;
});
