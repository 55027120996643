// Implementation based on Vercel's example repo:
// https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics

import Script from "next/script";
import React from "react";

export const GA_TRACKING_ID = "G-5S6YEX6J9C";

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
    window.gtag("config", GA_TRACKING_ID, {
        page_path: url,
    })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
    window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value: value,
    })
}

export const render = () => {
    return (
        <>
            {/* Global Site Tag (gtag.js) - Google Analytics */}
            <Script
                strategy="afterInteractive"
                src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
            />
            <Script
                id="gtag-init"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${GA_TRACKING_ID}', {
                            'page_path': window.location.pathname,
                            'linker': {
                                'domains': ['synega.no', 'signup.synega.com', 'user.synega.com'],
                                'accept_incoming': true
                            }
                        });
                    `,
                }}
            />
        </>
    );
}
