import axios from "axios";

export const BrregUrl = (keyword) => {
    if ((keyword?.length ?? 0) === 0) return "";

    let reg = /\D+/g;
    let words = keyword.match(reg);
    let isOrgNumber = words == null;
    let url = "https://data.brreg.no/enhetsregisteret/api/enheter?navn=";

    if (isOrgNumber) {
        reg = /\w+/g;
        words = keyword.match(reg);
        url = "https://data.brreg.no/enhetsregisteret/api/enheter?organisasjonsnummer=";
    }

    let searchTerm = words != null ? words.join("+") : "";
    return url + searchTerm;
};

export const BrregSearch = async (url) => {
    return await axios({
        method: "GET",
        url: url,
    }).then(res => {
        return res?.data?._embedded?.enheter;
    }).then(res => {
        return (res ?? []).map(item => {
            return {
                id: item?.organisasjonsnummer,
                name: item?.navn,
            }
        })
    }).catch(error => {
        Toast.error("Brønnøysundregistrene", "Noe gikk gale under søket");
        Sentry.captureException(error);
    })
}
